/*!
 * Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
// V4 shims compile (Web Fonts-based)
// -------------------------

@import 'functions';
@import 'variables';
@import 'shims';
