.carousel {

    .carousel-item {
        img {
           min-width: 100%;
            height: auto;
        }

        .carousel-caption {
            left: 3%;
            right: 3%;
            text-align: left;


            h3,h4,h5,h6 {
                a {
                    background-color: $uark-red;
                    opacity: .9;
                    padding: 10px 15px 6px 10px;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }
            }

            p {
                background-color: black;
                opacity: .7;
                padding: 5px 15px;
            }

            a, a:link {
                color: white;
                text-decoration: none;
            }
        }
    }
} 
