#uark-search-container {
    visibility: hidden !important;

    margin-top: 8px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 15px;
        margin-left: auto;
    }

    @include media-breakpoint-up(md) {
        visibility: visible !important;
    }

    @extend .ms-auto;
    @extend .d-none;
    @extend .d-md-flex;

    @include media-breakpoint-up('xl') {

        .list-group {
            min-width: 140px;
        }
    }
}

#uark-quick-links-container {
    top: 5px;

    display: inline;

    @extend .dropdown;
    // @extend .mr-1;
}

#uark-quick-links-button {
    @include media-breakpoint-up('md') {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;

        margin-right: 8px;
    }
}


#uark-quick-links {
    min-width: 20em;

    ul {
        display: block;
        columns: 9em auto;
    }

    li {
        font-size: 14px;

        min-width: 163px;
        padding: 0.5rem 1rem 0.5rem 1rem;

        border: none;
        background-color: transparent;
        @extend .list-group-item;
    }
}

#uark-mobile-quick-links {
    margin-bottom: 2ex;
    @extend .list-group;
    @extend .list-group-flush;
    @extend .list-unstyled;
}

#uark-search {
    @extend .form-control;
    @extend .mb-1;
    @extend .me-sm-1;
}

#uark-search,
#uark-search-mobile {
    font-size: 14px;

    display: inline-block;

    width: auto;
    height: $uark-searchbox-height;
    margin-right: 0 !important;

    border-radius: 0;

    .form-control:focus {
        color: #495057;
        border-color: #cacaca;
        outline: 0;
        background-color: #fff;
        box-shadow: inset 0 0 0.1rem 0.1rem #dee2e6;
    }
}

#uark-mobile-search-form {
    margin-bottom: 20px;
}

#uark-search-mobile {
    width: 100%;
}

#uark-search-button,
#uark-search-button-mobile {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;

    height: $uark-searchbox-height;
    margin: 0;
    padding: 3px 10px;

    border-radius: 0;
}

#uark-mobile-menu-site-id {

    .navbar-toggler:focus {
        outline: 0 auto transparent;
        box-shadow: 0 0 0 0;
    }
}

#uark-mobile-menu-button {
    white-space: nowrap;

    display: flex;
    visibility: visible;

    margin-left: auto;
    @extend .mt-2;

    @include media-breakpoint-up('md') {
        display: none;
        visibility: hidden;
    }

    @include media-breakpoint-up('xl') {

        &button:focus {
            outline: 0 auto transparent;
        }
    }

    .navbar-toggler:focus {
        outline: 0 auto transparent;
        box-shadow: 0 0 0 0;
    }
}
