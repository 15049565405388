.ox-regioneditbutton img{
	z-index: 100;
	margin-top:30px;
}


@media print {
	#directedit-footer a[href]:after {
	    content: '' !important;
    }
}

#oudirectedit{
    height:10px;width:10px;
    position: fixed;
    display: none;

    @media (min-width: 576px) {
        display: block;
    }

    
}

#directedit-footer {
    text-align:center;
	background-color: #424242;
	color:#fff;
	font-weight: 300;
	padding-bottom: 15px;
	display:block;

    a, 
    a:visited,
    a:link{
        color: #fff;
        text-decoration:none;
    }

    a:hover {
        text-decoration:underline;
    }
}


