#uark-sidebar {
    font-size: 1.05em;

    display: none;
    visibility: hidden;

    margin-top: 5px;

    @include media-breakpoint-up('md') {
        display: block;
        visibility: visible;
    }

    .nav {
        display: block;
    }



    ul.nav.nav-stacked {

        padding-left: 0;

        &:last-of-type {
            border-bottom: 0px;
        }

        li {
            @extend .nav-item;

            a {
                border-bottom: 2px solid #ccc !important;
            
                @extend .nav-link;

                &:hover,
                &:focus {
                    text-decoration: none;

                    color: $uark-red;
                }

                &.selected {
                    color: $uark-red;
                    position: relative;
                    padding-right: 19px;
                    font-weight: bold;

                    &:after {
                        font-family: 'Font Awesome 6 Pro', sans-serif;
                        position: absolute;
                        top: 50%;
                        margin: auto 0;
                        height: 100%;
                        min-height: 100%;
                        right: 8px;
                        bottom: 0;

                        content: fa-content($fa-var-chevron-right);
                        //refer to _variables.scss in node-modules
                    }
                }
            }
        }

        & > li {

            & > a:not(:last-child):after {
                font-family: 'Font Awesome 6 Pro', sans-serif;
                font-size: 0.8em;
                font-weight: 400;

                margin-left: 5px;

                content: fa-content($fa-var-chevron-down);
                //refer to _variables.scss in node-modules
            }
        }
    }
    //top level links


    
    //sub level links

    .nav-stacked>li>ul.nav-stacked {
        text-align-last: auto;
        padding-left: 0px;
        margin-left: 0px;
        text-indent: 0;
        border-left: 10px solid #ccc;

        li {
            margin-left: 0px;
        }
    
        &li>ul.nav-stacked {
            text-align-last: auto;
            padding-left: 0px;
            margin-left: 30px;
            text-indent: 0;
            border-left: 20px solid #ccc;

            li {
                margin-left: 0px;
            }
        }
    }

    ul {
        padding-left: 0;

        li {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;

            @extend .nav-link;

            &:hover,
            &:focus {
                text-decoration: none;

                color: $uark-red;
            }

            .selected {
                font-weight: bold;

                color: $uark-red;
            }

            /* subnav definitions*/
            ul {
            border-bottom: 2px solid #ccc;

                li {

                    &:last-of-type {
                        a {
                            border-bottom: none !important;
                        }
                    }

                    a {
                        padding: 3px 0 3px 5px;
                        margin-left: 0px;
                    }

                } 
            }

            a {
                font-size: 15px;

                margin: 0;
                padding: 6px 0 6px 20px;

                color: #666;

                @extend .nav-link;

                &:hover,
                &:focus {
                    text-decoration: none;

                    color: $uark-red;
                }
            }
        }
    }

//fix for subnav chevron position
ul li .selected:after {
    top: 5px;
}

}
