section.gray {

    .container {
        padding-top: var(--bs-gutter-x,.75rem);
    }

    position: relative;
    overflow: hidden;
    background-color: #F2F2F4;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    #prefooter, #section-footer { 
        margin-bottom: 0;
    }

    &::before, &::after {
        box-shadow: 0px 4px 26px 0px #4a4a4a, 0px 7px 16px 0px #5c5c5c, 0px 4px 5px 0px #bdbdbd;
        content: "";
        position: relative;
        height: 2px;
        top: -22px;
        left: 0;
        display: block;
    }

    &::after {
        box-shadow: 0px 3px 27px 3px #4a4a4a, 0px 7px 21px 1px #5c5c5c, 0px 4px 6px 1px #bdbdbd;
        top: unset;
        height: 2px;
        bottom: -22px;
    }
}


/* SECTION HEADERS STYLE
/////////////////////////// */

/*
section.landingPage h1, section h2 {
	color:#5c5c5c;
	text-transform: uppercase;			
	text-align: center;
	font-size: 30px;
	margin-bottom: 25px;
	display: table;
	white-space: nowrap;
}

*/

/* SECTION STYLE
/////////////////////////// */		

section {

	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	background-color: #fff;
	clear: both;


	.col-md-12 {
		position: relative;
	}

	h2 + .quick-links {
		margin-bottom: 0;
	}

	.quick-links {
		position: static;
		text-align: center;
		margin-top:-15px;
		margin-bottom: 25px;

		ul {
			list-style: none;
		}
	}

	.quick-links 

	.landingPage {
		padding-top:0;
	}

/*
	landingPage h1, h2 {
		white-space: normal;
	}

	.landingPage h1:before, 		
	.landingPage h1:after, 		
	h2:before,
	h2:after {
		content: "";
		display: none;
		width: 49%;
		background-size: 82% 2px;
		background: url(data:image/gif;base64,R0lGODdhAQABAIgAAEqMMQAAACwAAAAAAQABAAACAkQBADs=) repeat-x left center;
		background: -webkit-gradient(linear, 0 0, 0 100%, from(#ccc), to(#fefefe));
		background: -webkit-linear-gradient(#ccc, #fefefe) no-repeat center;
		background: -moz-linear-gradient(#ccc, #fefefe) no-repeat center / 82% 2px;
		background: -o-linear-gradient(#ccc, #fefefe no-repeat center);
		background: linear-gradient(#ccc, #fefefe) no-repeat center / 82% 2px;
	}

*/
		
	h4 {
		color: #620010;
		font-weight: 400;
	}
			
	.landingPage > div > img {
		-webkit-box-shadow: 0px 1px 5px 0px #4a4a4a;
		-moz-box-shadow: 0px 1px 5px 0px #4a4a4a;
		box-shadow: 0px 1px 5px 0px #4a4a4a;
	}
				
	ul.tags {
		list-style: none;
		margin:0;
		font-size: 12px;
	}
				
	ul.tags li a{
		color:#5c5c5c;
	}

	.split-list h4 {
		margin-top:2px;
	}
			
	.split-narrow {
		width:82px;
		margin-right:9px;
		float:left;
		min-height: 1px;
		height:100%;
		margin-left: 9px;
	}

	.split-wide {
		margin-left: 101px;
	}
				
	.split-wide h4:first-child {
		margin-top:0;
	}

	.nav>li>a {
		padding: 5px 10px;
	}

	@media (min-width: 768px) {
		.quick-links {
			position: absolute;
			right:3%;
			top:5px;
			text-align: left;
		}
	}
/*
	@media (min-width: 576px) {
		.landingPage h1:before, 		
		.landingPage h1:after, 		
		h2:before,
		h2:after {
			display: table-cell;
		}
		landingPage h1, h2 {
			white-space: nowrap;
		}
	}
*/

}

/* 
section:not(.gray) .card {
	background-color: $light;
}
*/

section:last-of-type {
	margin-bottom: 0;
	padding-bottom: 20px;
}
