//Override Bootstrap

$uark-red:  #9d2235;
$colors: (
    'uark-red':         $uark-red,
    'apple-blossom':    #fff,
    'birdsfoot-violet': #2f1332,
    'black-whetstone':  #000,
    'blue-winged-teal': #68c1be,
    'diana-butterfly':  #2b5269,
    'fall-maple':       #dbc759,
    'founders-walk':    #707a33,
    'gray-squirrel':    #c7c8ca,
    'kings-river':      #007698,
    'arkansas-marvel':  #ca6a29,
    'old-main-brick':   #611327,
    'ozark-mountains':  #3f7f7f,
    'pale-dawn':        #d5eae9,
    'quartz':           #f2f2f4,
    'razorback-red':    #9d2235,
    'spiderwort':       #655765,
    'spoofers-stone':   #424242,
    'summer-sky':       #9dc9d5,
    'white-river-sand': #dddcc5
);

$primary:   map-get($colors, 'uark-red');
$secondary: map-get($colors, 'spoofers-stone');
$success:   map-get($colors, 'founders-walk');
$info:      map-get($colors, 'kings-river');
$warning:   map-get($colors, 'arkansas-marvel');
$danger:    map-get($colors, 'old-main-brick');
$light:     map-get($colors, 'quartz');
$dark:      map-get($colors, 'diana-butterfly');

$alert-color-level:                 8.1 !default;

$gray-750: #464646;
$grays: map-merge((
    '750': $gray-750
), ());

$mark-bg: #fcf8a3;

$list-group-hover-bg: #e9ecef; // gray 200

$enable-shadows:                              true;
$enable-responsive-font-sizes:                true;

$link-decoration: underline;

$font-family-base: 'Lato', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$input-focus-border-color:              lighten(#343a40, 25%);
$input-focus-box-shadow:                0 0 0 0.2rem #adb5bd;

//UARK Custom Variables
$uark-searchbox-height: 35px;

$uark-header-background: rgb(242, 242, 244);
$uark-header-z-index:500;
$uark-mobile-topnav-z-index:1000;
$uark-t2-color: $gray-750;
$uark-t3-color: #333;

//FontAwesome Variable
$fa-font-path: '../../@fortawesome/fontawesome-pro/webfonts';

