#uark-logo-container {
    margin-right: 10px;
    margin-bottom: 15px;
    padding-left: 0;
}

#uark-logo {
    height: 50px;
}

%t2-common {
    line-height: 1.8;

    margin: 0;
    padding-left: 14px;
}

#uark-t2-container {
    order: 3;

    width: 100%;
    padding-bottom: 0;

    padding-left: 16px;

    border-left: 0;

    // @extend .flex-column;
    // @extend .align-self-center;
    @extend .navbar-text;

    &:empty {
         border-left: 0;
    }
}

#uark-t2-tagline {
    font-size: 14px;
    font-style: italic;

    margin-top: -7px;
    @extend %t2-common;
}

#uark-t2-tagline:empty {
    display: none;
}

#uark-t2 {
    font-size: 16px;
    line-height: 1.4;

    display: block;
    color: #464646;
    @extend %t2-common;

    > a {
        color: $uark-t2-color;

        &:hover,
        &:focus {
            color: $link-color;
        }
    }
}

#uark-t2,
#uark-t2-tagline {
    padding-left: 0;
}

#uark-t3 {
    font-size: 19px;
    font-weight: 700;

    line-height: 1.1;

    order: 90;

    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;

    // @extend .align-self-baseline;

    > a {
        color: $uark-t3-color;

        &:hover,
        &:focus {
            color: $link-color;
        }
    }
}

#uark-brand {
    padding-bottom: 10px;

    box-shadow: 0 0 0 white !important;
    @extend .navbar;

    @include media-breakpoint-up('xl') {
        padding-bottom: 6px;

        box-shadow: 0 2px 0 white;
    }
}

#uark-t2-menu {
    font-size: 14px;

    @extend #uark-t2;

    a {
        text-decoration: none;
    }
}

#uark-t3-menu {
    font-size: 17px;
    text-decoration: none;
    @extend #uark-t3;

    a {
        text-decoration: none;
    }
}

@include media-breakpoint-up ('sm') {

    #uark-t2-container {
        order: 10;

        width: auto;
        padding-right: 14px;
        padding-bottom: 0;
        padding-left: 0;

        border-left: 0;
    }

    #uark-t2,
    #uark-t2-tagline {
        padding-left: 0;
    }

    #uark-t3 {
        width: auto;
        margin-top: 5px;
        padding-left: 0;
    }
}

@include media-breakpoint-up ('lg') {

    #uark-t2-container {
        display: flex;
        align-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-evenly;

        min-height: 43px;

        margin: 0 0 0 4px ;
        padding: 0 0 0 9px ;

        border-left: 2px solid $gray-500;
    }

    #uark-t3 {
        padding: 0 0 0 0;
    }

    #uark-brand {
        padding-bottom: 3px;
    }
}


