#oudirectedit {
    text-decoration: none;

    position: absolute;
    z-index: 700;
    top: 0;
    left: 0;

    width: 10px;
    height: 10px;
}

#directedit-footer {
    text-align: center;
}

#directedit-footer {
    font-weight: 300;

    display: block;

    padding-bottom: 15px;

    color: #fff;
    background-color: #424242;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}
