.ensBlue {
	padding: 100px 0 20px 0 ;
	font-size: 14px;
	color: #FFF;
	display: block;
	background-color:#069;

    a {
        color:white;text-decoration:underline;
    }

    h3, h4 {
        color:#FFFFFF;
        margin:0 0 10px 0;
        border-bottom:solid 1px;
    }

    h4 {
        margin-top:8px;
    }

    .nav li a:hover {
        background-color:#FFFFFF;color:#069;
    }

    @media (min-width: 768px) {
        padding: 40px 0 20px 0 ;
        font-size: 14px;
        color: #FFF;
        display: block;
        background-color:#069;
    }

}

.ensRed {
	padding: 20px 40px;
	font-size: 24px;
	color: #FFF;
	display: block;
	background-color:#9D2235;
}
h3#razalert {
    color: #FFF;
    font-size: 1.5em;
    border-bottom: solid 1px;
}


/* Emergency Notification */	
/* ---------------------- */
	

.iwnFlair {
	position: absolute;
	left: 20%;
	bottom: -150px;
	background-color:#000;
	z-index:1;
}
.cloudOne {
	opacity: .05;
	font-size: 140px;
	position: absolute;
	left: 20px;
	bottom: -30px;
}
.cloudTwo {
	opacity: .085;
	font-size: 100px;
	position: absolute;
	left: 100px;
	bottom: 10px;
}
.cloudThree {
	opacity: .075;
	font-size: 120px;
	position: absolute;
	left: 180px;
	bottom: -25px;
}
.bustimes{color:#006699;padding:0;margin:0;}
.busIcon {
	background: url('//www.uark.edu/_resources/img/bus16.png') no-repeat scroll left top Transparent;
	display: inline-block;
	height: 16px;
	margin-bottom: 0px;
	width: 16px;
}
.bold {font-weight:bold;}
