.fa-bounce-click-hover:hover, .fa-bounce-click-hover:active  {   
    -webkit-animation-delay: var(--fa-animation-delay,0);
    animation-delay: var(--fa-animation-delay,0);
    -webkit-animation-direction: var(--fa-animation-direction,normal);
    animation-direction: var(--fa-animation-direction,normal);
    -webkit-animation-duration: var(--fa-animation-duration,1s);
    animation-duration: var(--fa-animation-duration,1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count,infinite);
    animation-iteration-count: var(--fa-animation-iteration-count,infinite);
    -webkit-animation-name: fa-bounce;
    animation-name: fa-bounce;
    -webkit-animation-timing-function: var(--fa-animation-timing,cubic-bezier(.28,.84,.42,1));
    animation-timing-function: var(--fa-animation-timing,cubic-bezier(.28,.84,.42,1));
}    

.footer-buttons {

    a {
        font-size: 17px;

        border-radius: 0;
    }

    .btn:hover strong,
    .btn:hover i {
        color: $uark-red;
    }

    .btn {
        text-align: left;
    }
}

#directedit-footer a {
    color:white;
    text-decoration:none;
}

#uark-footer {
    font-size: 14px;
    font-weight: 300;

    padding-top: 15px;
    padding-bottom: 0;

    color: #fff;
    background-color: #424242;

    #footer-logo {
        @extend h3;

        text-align: center;

        @include media-breakpoint-up('md') {
            text-align: left;
        }
    }

    #footer-news-header, #footer-events-header {
        @extend h4;
    }

    a {
        text-decoration: none;

        color: #fff;
    }

    .footer-events li,
    .footer-news li {
        padding-top: 0px;
    }

    a:hover {
        border-bottom: 1px solid #fff;
    }

    .event-list a:hover {
        text-decoration: underline;
    }

    .no-hover {
        border-bottom: 0 !important;
    }

    .btn-light {
        color: #333 !important;
        border-color: #ccc;
        background-color: #fff;
    }

    hr {
        box-shadow: 0px 1px 1px rgba(220 220 220 / .6);
        border-top: 1px solid #333;
        border-bottom: none;
        clear: both;
    }

    #footer-global-links,
    #social-stack {
        margin-bottom: .1em;
        text-align: center;
    }

    .events-footer li, 
    .footer-news li {
		padding-bottom: 10px;
	}
	.events-footer ol,
	.footer-news ul {
		list-style: none;
		margin-left:0;
		padding-left: 0;
	}

	.events-footer ol li a,
	.footer-news ul li a {
		color:#fff;

        &:hover {
            border-bottom: 1px solid #fff;
        }        
	}
	div#lclst_widget_footer {
		display: none;
	}
	ol.event-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li.event-item {
		margin-bottom: 3px;

        h4 {
                font-size: 14px !important;
                font-weight: 100;
                margin-bottom: 0px;
        }

        span.venue,
        span.date_range,
        span.time_range,
        time.event-date {
            display: none;
        }
	}

}


#social-stack a:hover {
    text-shadow: 0 0 4px #fff;

    border-bottom: 0;
}

.ua150-footer-link {
    text-decoration: none;

    display: block;

    color: #fff;

    img {
        width: 310px;

        a:hover {
            border-bottom: 0 !important;
        }
    }
}

#uark-campaign-btn {
    background-image: url('../assets/A.svg');
    background-repeat: no-repeat;
    background-position: left;
}

#events-footer,
#news-footer {
    @extend .list-unstyled;
}

#section-footer {
    padding-top: 15px;
    padding-bottom: 15px;

    border-top: 1px solid #ccc;
    background-color: #f2f2f4;
}

	/** LOCALIST FOOTER **/
footer {
    clear:both;

    div#lclst_widget_footer {
        display: none;
    }

    ol.event-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li.event-item {
        margin-bottom: 1em;

        h4 {
            font-size: 14px !important;
            font-weight: 100;
        }
    }

    li.event-item span.venue, 
    li.event-item span.date_range, 
    li.event-item span.time_range, 
    time.event-date {
        display: none;
    }
}

/* localist definitions */
span.lwn0 {
	display: none;
}

ul#lwe {
    list-style: none;
    margin: 0;
    padding: 0;
}

.lwl {
    display: none;
}
/* end localist definitions */





