/*
Give fontawesome icons an initial size so that the flash of delayed styling isn't as obvious
*/

.fa:before {
    content: ' ';
}

.fa-fw {
    width: 1.25em;
}
