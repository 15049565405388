.highlight {
    padding: 0;
}

// .clipboard-container {
//   @extend .d-flex;
//   @extend .justify-content-between;
//   @extend .align-items-start;
// }

:target:before {
/*
    display: block;

    width: 1px;
    height: 80px;
    margin-top: -80px;

    content: '';
*/
}

:target .anchorjs-link {
    opacity: 1;
    color: $gray-600;
}

.anchorjs-link {
    text-decoration: none;

    transition: all 0.5s ease-in-out;

    color: $gray-300;

    &:hover,
    &:focus {
        text-decoration: none;

        color: $gray-700;
    }
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */

    user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.force-select {
    -webkit-touch-callout: all; /* iOS Safari */

    user-select: all; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}
