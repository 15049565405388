
.breadcrumb {
    background-color: #f5f5f5;
    border-radius: 4px;
    list-style: none;
    margin-bottom: 20px;
    padding: 8px 15px 0px 15px;
}

.breadcrumb li {
    padding-right: 10px;
    padding-bottom: 10px;
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}
