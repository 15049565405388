@each $name, $color in $colors {

    $color-rgb: to-rgb($color);

    .text-#{$name} {
        color: $color;
    }

    .bg-#{$name} {
        //background-color: RGBA(var($color-rgb), var(--#{$prefix}bg-opacity, 1)) !important;

        hr {
            color: color-contrast($color);
        }

        .list-group-item {
            background-color: transparent;
        }

        h1,h2,h3,h4,h5,h6,p,ul,li,ol {
            color: color-contrast($color);
        }
        
        .card-footer.bg-white {
            h1,h2,h3,h4,h5,h6,p,ul,li,ol {
                color: $body-color;
            }
        }
    }

    .badge .bg-#{$name} {
        color: color-contrast($color);
    }
}

.bg-black a,
.bg-uark-red a,
.bg-gray-squirrel a,
.bg-spoofers-stone a,
.bg-black-whetstone a,
.bg-diana-butterfly a,
.bg-ozark-mountains a,
.bg-birdsfoot-violet a,
.bg-founders-walk a,
.bg-spiderwort a,
.bg-blue-winged-teal a,
.bg-arkansas-marvel a,
.bg-old-main-brick a
{
    color:#fff;
}
