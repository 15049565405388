/* stylelint-disable */

ol.outline {
    list-style-type: upper-roman;

    ol {
        list-style-type: upper-latin;

        ol {
            list-style-type: decimal;

            ol {
                list-style-type: lower-roman;
            }
        }
    }
}
/* stylelint-enable */
