@charset 'utf-8';
// PATH REFERENCES
// All paths are relative to THIS file, e.g. to link to A.svg use url('../assets/A.svg')

// Functions come first
@import '~bootstrap/scss/functions';

// Override variable defaults
//
// Here is where we customize the variables from Bootstrap's `variables.scss`.
// We copy-pasta variables we need from that source file, remove the `!default`
// flag, and finally change their values.

@import 'uark-variables';

// Variables, and mixins must come first.
//
// - Variables and mixins are dependant on functions
// - In order to override Sass's default variables, those default variables must
//   come first

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// Include any default map overrides here

//merge bootstrap colors with uark colors, this makes
//all the buttons and background colors
$theme-colors: map-merge($theme-colors, $colors);

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

@import '~bootstrap/scss/utilities';
@import 'bootstrap-4-variables';
// @import 'uark-fontawesome';
// Import Lato font
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-weight: 300;
    font-style: normal;
    src: local('Lato Light'), local('Lato-Light'),
        url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Lato';
    font-weight: 300;
    font-style: normal;
    src: local('Lato Light'), local('Lato-Light'),
        url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-style: normal;

    src: local('Lato Regular'), local('Lato-Regular'),
        url('https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2')
        format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-style: normal;

    src: local('Lato Regular'), local('Lato-Regular'),
        url('https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2')
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-weight: 700;
    font-style: normal;
    src: local('Lato Bold'), local('Lato-Bold'),
        url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Lato';
    font-weight: 700;
    font-style: normal;
    src: local('Lato Bold'), local('Lato-Bold'),
        url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

// Boostrap imports
// Layout & components
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

//Fontawesome Pro - 6.5.1 version
@import '../../@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '../../@fortawesome/fontawesome-pro/scss/solid.scss';
@import '../../@fortawesome/fontawesome-pro/scss/brands.scss';
@import '../../@fortawesome/fontawesome-pro/scss/regular.scss';
@import '../../@fortawesome/fontawesome-pro/scss/light.scss';
@import '../../@fortawesome/fontawesome-pro/scss/duotone.scss';
@import '../../@fortawesome/fontawesome-pro/scss/_duotone-icons.scss';
@import '../../@fortawesome/fontawesome-pro/scss/v4-shims.scss';


// scss-docs-end import-stack

//import bootstrap 4 support
@import 'bootstrap-4-support';

// UARK Specific
@import 'bootstrap-overrides';
@import 'header';
@import 'header-site-id';
@import 'header-search-container';
@import 'outlines';
@import 'text-util';
@import 'footer';
@import 'icons';
@import 'color-utils';
@import 'sidebar';
@import 'breadcrumbs';
@import 'direct-edit';
@import 'callouts';
@import 'uark-content';
@import 'uark-content-section';
@import 'uark-content-carousel';
@import 'uark-content-alerts';
@import 'uark-content-omnicms';
@import 'uark-content-headings';
@import 'uark-content-components';
@import 'uark-bannercontainer';
@import 'uark-widget-instagram';