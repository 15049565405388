html {
    scroll-padding-top: 1rem;
    scroll-behavior: smooth;

    @include media-breakpoint-up('sm') {
        scroll-padding-top: 8rem;
    }
}

#skiptocontent {

    a {
        position: absolute;
        z-index: $uark-header-z-index + 1;
        top: -40px;
        left: 10px;

        padding: 6px;

        transition: top 1s ease-out;

        color: white;
        border-radius: 0 0 3px 0;
        background: $uark-red;

        &:focus {
            position: absolute;
            top: 0;

            transition: top 0.1s ease-in;
        }
    }
}

#uark-header {
    border-top: 5px solid $uark-red;
    border-bottom: 1px solid #ccc;
    background-color: $uark-header-background;
    // box-shadow: 0 2px 5px rgba($black, 0.31);
    @supports (position: sticky) {
        @include media-breakpoint-up('md') {
            position: sticky;
            z-index: $uark-header-z-index;
            top: 0;
        }
    }

    a {
        text-decoration: none;
    }

    li.fa-home {
        text-decoration: none;
    }
}

.nav-item-home.fa-home {
    text-decoration: none;
}

#uark-site-id {
    display: grid !important;
    align-items: start;

    width: 100%;

    margin-bottom: 0 !important;
    grid-template-columns: 201px 1fr;
    grid-template-rows: 55px 1fr;
    grid-template-areas: 'nav-logo nav-search' 'nav-tiers nav-tiers';
    @extend .container;

    @include media-breakpoint-up('sm') {
        display: grid !important;

        margin-bottom: 0;
        grid-template-columns: 198px 1fr;
        grid-template-rows: 55px 1fr;
        grid-template-areas: 'nav-logo nav-search' 'nav-tiers nav-tiers';
    }

    @include media-breakpoint-up('lg') {
        display: grid !important;

        margin-bottom: 0;
        grid-template-columns: 198px 1fr 333px;
        grid-template-rows: 1fr;
        grid-template-areas: 'nav-logo nav-tiers nav-search';
    }

    @include media-breakpoint-up('xl') {
        padding-bottom: 0;
    }
}

.nav-logo {
    grid-area: nav-logo;

    align-self: baseline;
}

.nav-tiers {
    grid-area: nav-tiers;
}

#uark-mobile-menu {
    overflow-y: auto;

    width: 90%;

    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;

    border-top: 5px solid $uark-red;
    background-color: $uark-header-background;

    @include media-breakpoint-up('md') {
        display: none;
        visibility: hidden;
    }
}

.offcanvas-collapse.open {
    transform: translateX(0);
}

#nav-component-2 {
    position: sticky;
    z-index: 400;
    top: 73.5px;

    background-color: #f2f2f4;
    box-shadow: 0 2px 5px rgb(0 0 0 / 31%);

    @include media-breakpoint-up(xs) {
        display: none;
        visibility: hidden;
    }

    @include media-breakpoint-up(md) {
        display: inherit;
        visibility: visible;
    }
}

#uark-site-topnav {
    font-size: 1.2rem;
    line-height: 1.6rem;

    align-items: self-start;

    justify-content: space-between;

    padding: 0;

    @include media-breakpoint-up(sm) {
        font-size: 0.9rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 1rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.1rem;
    }

    @extend .container;
    @extend .navbar;
    // @extend .d-none;
    // @extend .d-md-block;


    ul>li>a {
        cursor: pointer;

        color: $gray-800;
        text-decoration: none !important;

        @extend .nav-link;

        @include transition(box-shadow 0.15s ease-in-out);

        &:hover,
        &:focus,
        &:focus-within,
        &[aria-expanded='true'] {
            line-height: 1.6rem;

            outline: none;

            //border-top: 4px solid $gray-500;
            box-shadow: 0px 3px 0 rgb(171 0 0) inset;

            @include transition(box-shadow 0.15s ease-in-out);
        }

        &:focus {
            outline: 2px solid $gray-500;
        }

        &:active {
            color:#fff;
        }

        .dropdown-item {


            &:active {
                background-color: transparent;
                color: #fff!important;
            }
            &:hover {
                box-shadow: none;
            }
        }

    }

    >ul:first-of-type {
        display: grid;

        justify-content: space-evenly;
        grid-auto-flow: column;
        grid-template-rows: minmax(42px, auto);

        width: 100%;
        @extend .nav;

        >li {
            @extend .nav-item;
            color: #343a40;
            cursor: pointer;
            transition: box-shadow .15s ease-in-out;

            &:hover,
            &:focus,
            &:focus-within,
            &[aria-expanded='true'] {
                line-height: 1.6rem;

                outline: none;

                //border-top: 4px solid $gray-500;
                box-shadow: 0px 3px 0 rgb(171 0 0) inset;

                @include transition(box-shadow 0.15s ease-in-out);

                ul {
                    @extend .dropdown-menu;
                    border-radius: 0 0 0.25rem 0.25rem;
                    box-shadow: 0.25rem 0.5rem 1rem rgb(0 0 0 / 15%);
                    
                    li {
                        @extend .dropdown-item;
                        padding: 0;
                    }
                }

            }



            // @extend .text-center;
        }
    }

    .nav-item-home {
        align-self: stretch;

        @include media-breakpoint-up('md') {
            align-self: center;
        }
    }

    .dropdown-menu .selected {
        border-top: 3px #9d2235 solid;
        background-color: #e4e4e4;
        box-shadow: 0 0 0;
    }

    .uark-nav-link {
        cursor: pointer;

        color: $gray-800;

        @extend .nav-link;

        @include transition(box-shadow 0.15s ease-in-out);

        &:hover,
        &:focus,
        &:focus-within,
        &[aria-expanded='true'] {
            line-height: 1.6rem;

            outline: none;

            //border-top: 4px solid $gray-500;
            box-shadow: 0px 3px 0 rgb(171 0 0) inset;

            @include transition(box-shadow 0.15s ease-in-out);
        }

        &:focus {
            outline: 2px solid $gray-500;
        }
    }

    .selected {
        //padding-top: 4px;
        line-height: 1.6rem;

        box-shadow: 0 4px 0 rgba($uark-red, 1) inset;
    }

    .never-selected {
        box-shadow: none !important;
    }
}

#uark-mobile-topnav {

    background-color: #dfdfdf !important;

    .accordion>ul {
        padding-bottom: 0;
        margin-bottom: 0;

        a.nav-link {
            padding: 8px 20px;
        }
    }


    a.nav-link {
        padding: 16px 20px;
        background-color: #fff;

        &.current {
            color: $uark-red;
            border-left: 5px solid $uark-red;
            padding: 16px 20px 16px 11px;
        }
    }
   

    > li {
        @extend .list-group-item;
    }

    > ul {
        align-items: stretch;
        @extend .list-group;

    }

    .selected {
        font-weight: bold;
    }

    [data-toggle] {
        position: relative;

        cursor: pointer;

        color: $uark-red;
    }

    .collapse,
    .collapsing {
        @extend .list-unstyled;

        li {
            line-height: 2.8rem;

            margin-left: 24px;

            border-bottom: 1px solid #e6e6e6;
        }

        :last-child {
            border-bottom: 0;
        }
    }
}

.collapse.show .selected {
    position: relative;

    &:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;

        position: absolute;
        top: 9px;
        left: 5px;

        display: inline-block;
        content: fa-content($fa-var-angle-double-right);
        //refer to _variables.scss in node-modules
    }
}

.uark-topnav-dropdown {
    @include media-breakpoint-up('xl') {
        text-align: center;

        min-width: 20rem;

        margin-top: -2px;

        border-top: 0;
        border-radius: 0 0 0.25rem 0.25rem;

        background-color: $uark-header-background;
    }
}
