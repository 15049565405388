.uark-page.container:has(#bannercont) {
    margin-top: 0;
}
#bannercont img {
    min-width:100%;
}

#bannercont-text {
    color: white;
    padding-top: 1rem;
    bottom: 0;
    left: 0;
}
