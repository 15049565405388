//
// Callouts
//

$uark-prefix: uark;

.uark-callout {
  --#{$uark-prefix}link-color-rgb: var(--uark-callout-link);
  --#{$uark-prefix}code-color: var(--uark-callout-code-color);

  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: inherit;
  background-color: #fcfcfd;
  border-left: .25rem solid #e0e4e8;

  h4 {
    margin-bottom: .25rem;
  }

  > :last-child {
    margin-bottom: 0;
  }

  + .uark-callout {
    margin-top: -.25rem;
  }

  .highlight {
    background-color: rgba($black, .05);
  }
}

// Variations

//@each $variant in $uark-callout-variants {
//  .uark-callout-#{$variant} {
//    --uark-callout-color: var(--bs-#{$variant}-text-emphasis);
//    --uark-callout-bg: var(--bs-#{$variant}-bg-subtle);
//    --uark-callout-border: var(--bs-#{$variant}-border-subtle);
//  }
//}

