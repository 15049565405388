.list-group-item-action,
.card-link {

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.btn {

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.disabled label {
    color: $gray-400;
}

.card {
    @extend .mb-4 !optional;
}

.card-deck .card {
    // sane default width. Forces cards to wrap if there are more than will fit
    // Breakpoint/columns
    // Extra Small | Small | Medium | Large | Extra Large
    //    1            2       2        3         4
    min-width: 240px;
}

pre code {
    // pre > code is set to 'inherit', which makes it too big.
    // Adding this knocks it back down to the same size as 'code'
    @include font-size($code-font-size);
}
