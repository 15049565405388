/* looking to remove the auto style for landing and section */
h1.fancy-heading,
h2.fancy-heading,
h3.fancy-heading,
h4.fancy-heading,
h5.fancy-heading,
h6.fancy-heading 
{
    display: grid;
    text-align: center;
    text-transform: uppercase;
    white-space: normal;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 0 20px;
    align-items: center;

    .text-nowrap {
        display: contents;
    }
}

h1.fancy-heading:before,
h2.fancy-heading:before,
h3.fancy-heading:before,
h4.fancy-heading:before,
h5.fancy-heading:before,
h6.fancy-heading:before,
h1.fancy-heading:after, 
h2.fancy-heading:after, 
h3.fancy-heading:after, 
h4.fancy-heading:after, 
h5.fancy-heading:after, 
h6.fancy-heading:after 
 {
    content: "";
    background-size: 82% 2px;
    background: url(data:image/gif;base64,R0lGODdhAQABAIgAAEqMMQAAACwAAAAAAQABAAACAkQBADs=) repeat-x left center;
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#ccc), to(#fefefe));
    background: -webkit-linear-gradient(#ccc, #fefefe) no-repeat center;
    background: -moz-linear-gradient(#ccc, #fefefe) no-repeat center / 82% 2px;
    background: -o-linear-gradient(#ccc, #fefefe no-repeat center);
    background: linear-gradient(#ccc, #fefefe) no-repeat center / 82% 2px;
    display: block;
    height: 2px !important;
    min-width: 70px;
    width: auto;
}