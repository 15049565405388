a.btn-default.uaRed {
    background-color: $uark-red;
    color: #fff;
    border-color: #F2F2F4;
}

.uark-liststack {
    @extend .list-group, .list-group-flush;

    li {
        @extend .list-group-item;
        
        border-top: 1px solid rgba(0,0,0,.065);
        background-color: unset;
    }
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;

    p {
        padding-bottom: 0;
        margin-bottom: 0;
    }

}


/* VIDEO FULL WIDTH TO CONTAINER
-------------------------------------------------- */

video {
  width: 100% !important;
  height: auto !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* UTILITY CLASSES
-------------------------------------------------- */


/*PHP Directory Profile Name Class Update*/
section h2.name
{
	text-transform: none;
	color: #000;
	margin: 25px 0 12px 0;
	font-size:29px;
	text-align:left;
	white-space:normal;
	display:inline-block; /*rule remove the header horizontal link in the Advancement profile names*/
}

/*Hide Department on grid*/
.grid_department 
{
	display: none;
}


.split-alt-narrow {
  width: 120px;
  float: left;
  margin-right: 9px;

  h4 {
    font-size: 18px;
    line-height: 1em;
    margin: 4px 0 0 0;
  }
}

.split-alt-wide img {
  margin-bottom: 10px;
}

.red-rect {
  width: 104px;
}

.red-date {
  text-align: center;
  width: 80px;
  min-height: 60px;
  font-size: 18px;
  vertical-align: middle;

  h4 {
    text-transform: uppercase;
  }
}

.red-shadow {
  background-color: #8e0018;
  box-shadow: inset 0px 0px 20px #222;
  padding: 8px 13px;

  h4 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    small {
      font-size: 14px;
      color: #fff;
      font-weight: 300;
      text-transform: lowercase;
    }

    strong {
      font-size: 25px;
      font-weight: 300;
    }
  }
}

.bigCopy {
	line-height: 120%;
	font-size: 18px;
	font-weight: lighter;

    @media (min-width:768px) {
        line-height: 150%;
        font-size: 22px;
    }
}

.pullQuote {
	font-style:italic;
	font-size:120%;
}

.shadow-bottom, .shadow-bottom-inner {
	position:relative;
	overflow:hidden;
	padding-bottom:20px;

    &:after {
        content: "";
        position:absolute;
        z-index: -1;
        width:95%;
        bottom: 20px;
        height: 10px;
        left: 3%;
        border-radius: 100px / 5px;
        box-shadow:0 0 18px rgba(0,0,0,0.8);
    }
}

.shadow-bottom-inner {
	padding-bottom:0;

    &:after {
        bottom: -5px;
        height: 5px;
        box-shadow:0 0 10px rgba(0,0,0,0.9);
    }
}



.shadow-top {
	position:relative;
	overflow:hidden;
	border-top:1px solid #ddd;
	padding-top:20px;

    &:before {
        content: "";
        position:absolute;
        z-index: 1;
        width:96%;
        top: -10px;
        height: 10px;
        left: 2%;
        border-radius: 100px / 5px;
        box-shadow:0 0 18px rgba(0,0,0,0.6);
    }
}


/*  cards support */
.uark-card-stack .card {
    min-height: 190px;

    a {
        color:#fff;
    }

    .card-overlay {
        padding:0;

        img {
            object-fit:cover;
            min-height:189px;
        }
    }

    .card-img-overlay {
        top:auto; padding: 0 1rem;
        
        a {
            height:100%;
            text-decoration: none;
        }
    }

    .card-title {
        font-size: 30px;color: #FFFFFF;
        text-shadow: 0px 0px 5px rgb(0 0 0);
    }

}


.uark-card-stack div:nth-child(1) .card {
    margin-top:0.75rem;
}


@media (min-width:768px) {
    .uark-card-stack div:nth-child(n+3) .card {
        margin-top:0;
    }
    .uark-card-stack div:nth-child(-n+2) .card {
        margin-top:0.75rem;
    }
}
/* Adding top margin to all four cards in 4-across layout */
@media (min-width:992px) {
    .uark-card-stack div:nth-child(-n+4) .card  {
        margin-top:0.75rem;
    }
}
		
/*  END cards support */


/* Forces hero image at the top of the page to be full width */
.landingPage #content img.img-fluid:first-of-type {
    min-width: 100%;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,  
    object,  
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

#main-content {
    h1:first-of-type {
        margin-top: 15px;
    }
}

.uark-page.container {
    margin-top: 25px;
}

#uark-t2-container a, #site-heading a {
    color: #424242;
    font-size: 22px;
    text-decoration: none;
}

/* PRE-FOOTER
-----------------------------------------*/
/* this will hide the left 'Stay Connected' area */
.socialMedia {
     display:none;
}
.social-media-prefooter a i[class*="icon-"], .social-media-prefooter a i[class*="fa-"] {
	text-decoration: none;
	font-size: 120%;
	display: inline-block;
	width: 20px;
}

.social-media-prefooter a i[class*="icon-"]:hover, .social-media-prefooter a i[class*="fa-"]:hover {
  text-shadow: 0 0 2px rgba( 0, 0, 0, 0.5);
}
