.es-header-user-username, .eapps-instagram-feed-header-stats-item-label  {
    color: #9d2235;
}

.eapps-instagram-feed-header-user-info-caption, .eapps-instagram-feed-header-stats-item-label  {
    opacity: 1 !important;
}

.eapps-instagram-feed-header-follow-button-label {
    color: #ffffff;
}